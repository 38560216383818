import dynamic from 'next/dynamic';

export const DynamicInstagramEmbed = dynamic(
  () => import('react-social-media-embed').then((mod) => mod.InstagramEmbed),
  { ssr: false },
);

export const DynamicYouTubeEmbed = dynamic(() => import('react-social-media-embed').then((mod) => mod.YouTubeEmbed), {
  ssr: false,
});

export const DynamicTwitterEmbed = dynamic(() => import('react-social-media-embed').then((mod) => mod.TwitterEmbed), {
  ssr: false,
});

export const DynamicTikTokEmbed = dynamic(() => import('react-social-media-embed').then((mod) => mod.TikTokEmbed), {
  ssr: false,
});
