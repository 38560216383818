import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import axios from 'axios';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';

interface IntersectionObserverEntry {
  boundingClientRect: DOMRectReadOnly;
  rootBounds: DOMRectReadOnly | null;
  intersectionRect: DOMRectReadOnly;
  intersectionRatio: number;
  isIntersecting: boolean;
  target: Element;
  time: number;
}

interface IAdMemeObserverProps {
  slug: string | null;
  postId: number;
  postCardId: string;
  adUrls: string[] | null;
  isAdPost: boolean;
}

export const useAdMemeObserver = ({ slug, postId, postCardId, adUrls, isAdPost }: IAdMemeObserverProps) => {
  const cookies = new Cookies();
  const [isContentShown, setIsContentShown] = useState<boolean>(false);
  const isAdUrls = !!adUrls?.length;

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      // Update the state based on whether the component is in the viewport
      setIsContentShown(entry.isIntersecting);
    });
  };

  useEffect(() => {
    if (!isAdPost || isContentShown) return;

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };

    const targetElement = document.getElementById(postCardId);

    if (targetElement) {
      const observer = new IntersectionObserver(handleIntersection, options);
      observer.observe(targetElement);

      // eslint-disable-next-line consistent-return
      return () => observer.disconnect();
    }
  }, [isAdPost, isContentShown]);

  useEffect(() => {
    if (isContentShown && isAdUrls) {
      // eslint-disable-next-line @typescript-eslint/require-await
      const fetchAdMemeObserver = async () => {
        const consentString = cookies.get('euconsent-v2') as string;
        const { gdprAppliesGlobally } = window as Window;
        const isGdprAppliesGlobally = gdprAppliesGlobally ? 1 : 0;

        const updatedUrls = adUrls?.map((url) =>
          url
            ?.replace('[timestamp]', dayjs().format())
            .replace('[gdpr_applies]', `${isGdprAppliesGlobally}`)
            .replace('[gdpr_consent]', `${consentString}`),
        );

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        updatedUrls?.forEach(async (url: string) => {
          try {
            await axios.get(url);
          } catch (error) {
            console.error(`Error making AdMeme GET request for ${url}`, error);
          }
        });
      };

      void fetchAdMemeObserver();
    }
  }, [isContentShown, isAdUrls]);

  useEffect(() => {
    if (isContentShown && isAdPost) {
      ReactGA.event('mem_ads_displayed', {
        post_id: postId,
        post_title: slug,
      });
    }
  }, [isContentShown, isAdPost]);
};
