import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import Image from 'next/image';
import clsx from 'clsx';

import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { ICategoryData } from '@/api/categories/interfaces';
import { Wrapper } from '@/components/atoms/Wrapper';
import { cn } from '@/utils/cn';

import { Badge } from './Badge';

interface ICategoryRowProps {
  data: ICategoryData;
  isSidebarMobile?: boolean;
  closeSidebar?: () => void;
}

export const CategoryRow = ({ data, isSidebarMobile, closeSidebar }: ICategoryRowProps) => {
  const router = useRouter();
  const [selectedRow, setSelectedRow] = useState<boolean>(false);
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const { pathname, query } = router;
  const { categoryId } = query;
  const { name, isConstant, icon } = data.attributes;
  const iconSource = icon?.data?.attributes?.url;
  const categoryPath = `/categories/${data?.id}`;

  const navigateToCategoryPage = () => {
    if (closeSidebar) {
      closeSidebar();
    }
    ReactGA.event('navi_category_tap', {
      category_id: categoryId,
      category_name: name,
    });
    updateInterstitialActionCount();
    window.location.href = categoryPath;
  };

  useEffect(() => {
    const isSelected = pathname === '/categories/[categoryId]' && Number(categoryId) === data?.id;
    setSelectedRow(isSelected);
  }, [pathname, categoryId]);

  return (
    <Wrapper
      onClick={navigateToCategoryPage}
      className={clsx(
        'items-center justify-between p-2 rounded-lg cursor-pointer hover:bg-gray-900 hover:ease-in hover:duration-150',
        selectedRow && 'bg-gray-900',
      )}
      row
      fullWidth
    >
      <Wrapper className="items-center gap-2.5 truncate" row fullWidth>
        {!!iconSource && (
          <Wrapper className={cn('relative', !isSidebarMobile ? 'w-8 h-8' : 'w-6 h-6')}>
            <Image src={iconSource} alt={name} layout="fill" />
          </Wrapper>
        )}
        <p className={clsx(!isSidebarMobile ? 'text-base' : 'text-sm', 'font-medium text-white truncate')}>{name}</p>
      </Wrapper>
      {!isConstant && <Badge />}
    </Wrapper>
  );
};
