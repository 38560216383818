import { Wrapper } from '@/components/atoms/Wrapper';

import { LinkItem } from './LinkItem';

interface ILinksSectionProps {
  source: string | null;
}

export const LinksSection = ({ source }: ILinksSectionProps) => {
  const splitSources = (combinedString: string): string[] => {
    if (!combinedString) return [];
    return combinedString?.split(',');
  };

  const splittedSources = splitSources(source as string);

  return (
    <Wrapper className="flex-wrap gap-3" row>
      {splittedSources.length > 0 && (
        <>
          {splittedSources.map((item) => {
            return <LinkItem key={item} title="Źródło:" link={item} />;
          })}
        </>
      )}
      {splittedSources.length === 0 && source && <LinkItem title="Źródło:" link={source} />}
    </Wrapper>
  );
};
