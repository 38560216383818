import clsx from 'clsx';
import { useRouter } from 'next/router';

import { DataItem } from '@/api/posts/interfaces';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Button } from '@/components/atoms/Button';

import { UserSection } from '../UserSection';
import { TagCategorySection } from '../TagCategorySection';

interface IBlogPostProps {
  data: DataItem;
  isPinnedBadgeVisible?: boolean;
  isFeedPost?: boolean;
}

export const BlogPost = ({ data, isPinnedBadgeVisible, isFeedPost = false }: IBlogPostProps) => {
  const router = useRouter();
  const { pathname } = router;
  const isSinglePostPathname = pathname === '/post/[...slug]' && !isFeedPost;
  const { type, content, postCoverPicture, title, postVideo, author, publishedAt, tags, categories } = data.attributes;
  const userAvatar = author?.data?.attributes?.avatar?.data?.attributes?.formats?.medium?.url;
  const picture = postCoverPicture?.data?.attributes?.formats?.medium?.url;
  const userName = author?.data?.attributes?.username;
  const video = postVideo?.data?.attributes.url;
  const postUrl = `post/${data?.id}/${title}`;
  const postType = type?.data?.[0]?.id;
  const userId = author?.data?.id;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {postType === 1 && (
        <Wrapper className="mb-4" fullWidth>
          <Wrapper className="flex-col-reverse justify-between md:flex-row" fullWidth>
            <Wrapper fullWidth>
              {isPinnedBadgeVisible && (
                <p className="text-sm font-medium text-black px-2 py-0.5 mb-5 ml-4 bg-primary-600 rounded-md w-fit">
                  Przypięty
                </p>
              )}
              <Wrapper className="gap-3 px-3 regular:px-6 mb-6">
                <UserSection id={userId} name={userName} date={publishedAt} avatar={userAvatar} />
                <h2
                  className={clsx(
                    'text-2xl font-semibold hover:opacity-90 cursor-pointer',
                    isSinglePostPathname && 'hover:!opacity-100 !cursor-default',
                  )}
                  itemProp="headline"
                >
                  {title}
                </h2>
                <TagCategorySection
                  tags={tags}
                  postId={data?.id}
                  postTitle={title}
                  postUrl={postUrl}
                  categories={categories}
                  isBlogPostInFeed
                />
              </Wrapper>
              {content && (
                <Wrapper className="px-3 regular:px-6" fullWidth>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: content }}
                    className={clsx(
                      'text-gray-300 font-light',
                      content ? 'mb-4' : 'my-3',
                      !isSinglePostPathname && 'threeLinesEllipsis',
                    )}
                  />
                </Wrapper>
              )}
              {!isSinglePostPathname && (
                <Button variant="text" className="mx-3 regular:mx-6 mb-6">
                  Czytaj dalej {'>'}
                </Button>
              )}
            </Wrapper>
            {picture && !video && (
              <Wrapper
                className={clsx(
                  'md:mb-0 mb-6 px-3 regular:px-6 md:px-0 md:mr-6 md:max-w-256 rounded-lg',
                  !isSinglePostPathname && 'cursor-pointer',
                )}
                fullWidth
              >
                <img src={picture} alt={title} className="md:object-cover md:h-256 rounded-xl" itemProp="image" />
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
      )}
    </>
  );
};
