import clsx from 'clsx';

interface ICameraProps {
  className?: string;
}

export const Camera = ({ className }: ICameraProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 49"
    className={clsx('stroke-white', className)}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.17157 15.2116C6.42143 15.9618 6 16.9792 6 18.04V36.04C6 37.1009 6.42143 38.1183 7.17157 38.8685C7.92172 39.6186 8.93913 40.04 10 40.04H38C39.0609 40.04 40.0783 39.6186 40.8284 38.8685C41.5786 38.1183 42 37.1009 42 36.04V18.04C42 16.9792 41.5786 15.9618 40.8284 15.2116C40.0783 14.4615 39.0609 14.04 38 14.04H36.14C35.4816 14.0401 34.8334 13.8777 34.2529 13.5672C33.6723 13.2567 33.1774 12.8077 32.812 12.26L31.188 9.82004C30.8226 9.27237 30.3277 8.82337 29.7471 8.51286C29.1666 8.20235 28.5184 8.03994 27.86 8.04004H20.14C19.4816 8.03994 18.8334 8.20235 18.2529 8.51286C17.6723 8.82337 17.1774 9.27237 16.812 9.82004L15.188 12.26C14.8226 12.8077 14.3277 13.2567 13.7471 13.5672C13.1666 13.8777 12.5184 14.0401 11.86 14.04H10C8.93913 14.04 7.92172 14.4615 7.17157 15.2116Z"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.2426 30.2827C29.3679 29.1575 30 27.6313 30 26.04C30 24.4487 29.3679 22.9226 28.2426 21.7974C27.1174 20.6722 25.5913 20.04 24 20.04C22.4087 20.04 20.8826 20.6722 19.7574 21.7974C18.6321 22.9226 18 24.4487 18 26.04C18 27.6313 18.6321 29.1575 19.7574 30.2827C20.8826 31.4079 22.4087 32.04 24 32.04C25.5913 32.04 27.1174 31.4079 28.2426 30.2827Z"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
