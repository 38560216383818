import { ICategoryData } from '@/api/categories/interfaces';
import { Card } from '@/components/atoms/Card';
import { Wrapper } from '@/components/atoms/Wrapper';
import { CategoryRow } from '@/components/molecules/CategoryRow';

interface ICategoriesContainerProps {
  categoriesData: ICategoryData[];
}

export const CategoriesContainer = ({ categoriesData }: ICategoriesContainerProps) => (
  <Card variant="black" className="rounded-xl w-full !p-0">
    <h3 className="py-4 px-4 font-semibold text-base border-b border-gray-800">Kategorie</h3>
    <Wrapper className="py-2 px-2 gap-0.5" fullWidth>
      {categoriesData.map((data) => {
        return <CategoryRow key={data?.id} data={data} />;
      })}
    </Wrapper>
  </Card>
);
