import { useEffect, useRef, useState, MouseEvent } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';

import { Wrapper } from '@/components/atoms/Wrapper';
import { cn } from '@/utils/cn';
import { useMediaQuery } from 'usehooks-ts';

interface PostContentSectionProps {
  content: string;
  contentIsQuote: boolean;
  quoteAuthor: string | null;
}

export const PostContentSection = ({ content, contentIsQuote, quoteAuthor }: PostContentSectionProps) => {
  const [isWholeTextVisible, setWholeTextVisible] = useState<boolean>(false);
  const [numberOfLines, setNumberOfLines] = useState<number>(0);
  const paragraphRef = useRef<HTMLInputElement>(null);
  const isDesktop = useMediaQuery('(min-width: 950px)');
  const maxLinesCount = isDesktop ? 14 : 10;
  const isTextEllipsis = numberOfLines > maxLinesCount && !isWholeTextVisible;

  useEffect(() => {
    const checkNumberOfLines = () => {
      if (!paragraphRef?.current) return;
      const rect = paragraphRef?.current?.getBoundingClientRect();
      const lineHeight = parseFloat(getComputedStyle(paragraphRef?.current)?.lineHeight);
      const lines = Math.ceil(rect.height / lineHeight);

      setNumberOfLines(lines);
    };

    checkNumberOfLines();
  }, []);

  return (
    <Wrapper className="px-1.5 regular:px-2" fullWidth>
      <Wrapper
        className={cn(
          'bg-gray-900 border border-gray-700 rounded-xl p-4 md:px-6',
          contentIsQuote && 'text-center items-center gap-3',
        )}
        fullWidth
      >
        <p
          ref={paragraphRef}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
          className={cn('text-white break-words w-full', isTextEllipsis && 'fourteenLinesEllipsis md:tenLinesEllipsis')}
        />
        {contentIsQuote && !isTextEllipsis && (
          <Wrapper className="items-center max-w-full gap-1" row>
            <Image src="/icons/quote.svg" alt="quote" width={25} height={25} />
            <p className="text-xs font-bold text-white break-words max-w-full pr-2">{quoteAuthor}</p>
          </Wrapper>
        )}
        {isTextEllipsis && (
          <button
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setWholeTextVisible(true);
            }}
            className={cn(
              'flex text-white text-sm font-bold hover:opacity-80 items-center gap-1.5',
              !contentIsQuote && 'self-center mt-3',
            )}
          >
            Rozwiń
            <ChevronDownIcon width={15} strokeWidth={3} />
          </button>
        )}
      </Wrapper>
    </Wrapper>
  );
};
