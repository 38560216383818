import { useEffect, useState } from 'react';
import Image from 'next/image';

import { useLikes } from '@/hooks/useLikes';
import { cn } from '@/utils/cn';
import { PostLikeUp } from '@/components/icons/PostLikeUp';
import { PostLikeDown } from '@/components/icons/PostLikeDown';
import { Wrapper } from '@/components/atoms/Wrapper';
import { CalendarContainer } from '@/components/atoms/CalendarContainer';

interface ILikesContainer {
  postId: number;
  postTitle: string;
  likesCount: number;
  dislikesCount: number;
  isLocalLikesCount: boolean;
  handleLocalLikesCount: () => void;
}

export const LikesContainer = ({
  postId,
  postTitle,
  likesCount,
  dislikesCount,
  isLocalLikesCount,
  handleLocalLikesCount,
}: ILikesContainer) => {
  const { currentLikesCount, currentDislikesCount, likedPost, dislikedPost, handleLikeClick, handleDislikeClick } =
    useLikes({
      postId,
      postTitle,
      likesCount,
      dislikesCount,
      isLocalLikesCount,
      handleLocalLikesCount,
    });
  const [isLikeAnimation, setLikeAnimation] = useState<boolean>(false);
  const [isDislikeAnimation, setDislikeAnimation] = useState<boolean>(false);
  const [isLikePressed, setLikePressed] = useState<boolean>(false);
  const [isDislikePressed, setDislikePressed] = useState<boolean>(false);

  const onLikeTouchEnd = () => {
    const timer = setTimeout(() => {
      setLikePressed(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  const onDislikeTouchEnd = () => {
    const timer = setTimeout(() => {
      setDislikePressed(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  const animateLike = () => {
    if (likedPost) return;
    setLikeAnimation(true);
  };

  const animateDislike = () => {
    if (dislikedPost) return;
    setDislikeAnimation(true);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isLikeAnimation) {
      const timer = setTimeout(() => {
        setLikeAnimation(false);
      }, 300);

      return () => clearTimeout(timer);
    }

    if (isDislikeAnimation) {
      const timer = setTimeout(() => {
        setDislikeAnimation(false);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isLikeAnimation, isDislikeAnimation]);

  return (
    <Wrapper className="items-center -ml-2" row>
      <button
        onClick={(e) => {
          void handleLikeClick(e);
          animateLike();
        }}
        onTouchStart={() => setLikePressed(true)}
        onTouchEnd={onLikeTouchEnd}
        className={cn(
          'hover:ease-in hover:duration-150 hover:bg-gray-900 flex items-center rounded-lg gap-1.5 [&>div:last-child]:hover:bg-zinc-800 [&>div:last-child]:hover:ease-in [&>div:last-child]:hover:duration-150',
          isLikePressed && 'bg-gray-900',
        )}
      >
        <Wrapper className="py-3 pl-2">
          <PostLikeUp
            className={cn(likedPost && 'stroke-primary-600 text-primary-600', isLikeAnimation && 'animate-wiggle')}
          />
        </Wrapper>
        <CalendarContainer counter={currentLikesCount} isLikedPost={!!likedPost} />
      </button>
      <Wrapper className="mx-1 w-1 h-12 relative">
        <Image alt="dots" src="/icons/dots.svg" layout="fill" />
      </Wrapper>
      <button
        onClick={(e) => {
          void handleDislikeClick(e);
          animateDislike();
        }}
        onTouchStart={() => setDislikePressed(true)}
        onTouchEnd={onDislikeTouchEnd}
        className={cn(
          'hover:ease-in hover:duration-150 hover:bg-gray-900 flex items-center rounded-lg gap-1.5 [&>div:first-child]:hover:bg-zinc-800 [&>div:first-child]:hover:ease-in [&>div:first-child]:hover:duration-150',
          isDislikePressed && 'bg-gray-900',
        )}
      >
        <CalendarContainer counter={currentDislikesCount} isDislikedPost={!!dislikedPost} />
        <Wrapper className="py-3 pr-3">
          <PostLikeDown
            className={cn(dislikedPost && 'stroke-red-600 text-red-600', isDislikeAnimation && 'animate-wiggle')}
          />
        </Wrapper>
      </button>
    </Wrapper>
  );
};
