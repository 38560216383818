import { ReactNode } from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import { cva } from 'class-variance-authority';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import { CloseButton } from '@/components/atoms/CloseButton';
import { Wrapper } from '@/components/atoms/Wrapper';

interface HeadlessModalProps {
  variant: 'default' | 'authModal';
  isArrowLeft?: boolean;
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  title?: string;
  isLogo?: boolean;
  onArrowLeftClick?: () => void;
  closeModal: () => void | ((value: boolean) => void);
}

const modalVariants = cva('', {
  variants: {
    variant: {
      default: 'border border-gray-800 rounded-lg overflow-y-auto bg-gray-950 p-4 m-4',
      authModal:
        'sm:border sm:border-gray-800 sm:rounded-lg h-full sm:max-h-[90vh] px-2 sm:px-4 pt-6 pb-6 overflow-y-auto bg-gray-950',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const HeadlessModal = ({
  children,
  isOpen,
  variant,
  title = '',
  className,
  isLogo = true,
  isArrowLeft = false,
  closeModal,
  onArrowLeftClick,
}: HeadlessModalProps) => {
  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75" />
        </Transition.Child>

        <div className="overflow-y-auto fixed inset-0 w-screen">
          <div
            className={clsx(
              'flex items-center justify-center',
              variant === 'default' ? '!h-auto !min-h-screen' : '!h-full sm:!h-auto sm:!min-h-screen',
            )}
          >
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              className={clsx('w-full h-full sm:my-auto', className)}
            >
              <Dialog.Panel className={modalVariants({ variant })}>
                <Dialog.Title className="flex justify-between items-center pb-5 pl-2">
                  <Wrapper row>
                    {isArrowLeft && (
                      <button onClick={onArrowLeftClick}>
                        <ArrowLeftIcon className="hover:opacity-80 text-white stroke-2 mr-3" width={24} height={24} />
                      </button>
                    )}
                    {!!title && <h2 className="text-base font-bold text-white sm:text-xl">{title}</h2>}
                    {!title && isLogo && (
                      <Image src="/icons/fangol-logo.svg" alt="Fangol logo" width={91} height={28} priority />
                    )}
                  </Wrapper>
                  <CloseButton variant="default" onClick={closeModal} />
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
