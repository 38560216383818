import { Card } from '@/components/atoms/Card';
import { Wrapper } from '@/components/atoms/Wrapper';

interface ILinkItemProps {
  title: string;
  link: string;
}

export const LinkItem = ({ title, link }: ILinkItemProps) => (
  <Card variant="darkGray" className="!p-2 rounded-md">
    <Wrapper className="max-w-200 gap-1" row>
      <p className="text-xs">{title}</p>
      <p className="text-xs truncate">{link}</p>
    </Wrapper>
  </Card>
);
