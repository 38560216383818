import { StateCreator, create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { createHookStore } from './index';

interface ISurveyItem {
  postId: number;
  answerId: number;
  totalVotes: number;
}

export interface ISurveyStore {
  surveyState: ISurveyItem[];
  setSurveyState: (value: ISurveyItem[]) => void;
}

const initialState = {
  surveyState: [],
  setSurveyState: () => {},
};

const emptyState: StateCreator<ISurveyStore> = (set) => ({
  ...initialState,
  setSurveyState: (surveyState) => set({ surveyState }),
});

export const surveyStore = create(devtools(persist(emptyState, { name: 'survey-persist' }), { name: 'survey' }));
export const useSurveyStore = createHookStore<ISurveyStore>(surveyStore, initialState);
