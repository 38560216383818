import { useRouter } from 'next/router';
import clsx from 'clsx';

import { DataItem } from '@/api/posts/interfaces';
import { extractPlatform } from '@/utils/extractPlatform';

import { Wrapper } from '@/components/atoms/Wrapper';
import {
  DynamicInstagramEmbed,
  DynamicTikTokEmbed,
  DynamicTwitterEmbed,
  DynamicYouTubeEmbed,
} from '@/components/atoms/DynamicReactSocialMediaEmbed';

import { SurveySection } from '../SurveySection';
import { LinksSection } from '../LinksSection';
import { UserSection } from '../UserSection';
import { TagCategorySection } from '../TagCategorySection';
import { PostContentSection } from '../PostContentSection';

interface IEmbedPostProps {
  data: DataItem;
  isFeedPost?: boolean;
}

export const EmbedPost = ({ data, isFeedPost = false }: IEmbedPostProps) => {
  const router = useRouter();
  const { pathname } = router;
  const isSinglePostPathname = pathname === '/post/[...slug]' && !isFeedPost;
  const {
    contentIsQuote,
    additionalText,
    embedMediaUrl,
    pollQuestion,
    quoteAuthor,
    publishedAt,
    categories,
    content,
    author,
    source,
    title,
    type,
    tags,
  } = data.attributes;
  const userAvatar = author?.data?.attributes?.avatar?.data?.attributes?.formats?.medium?.url;
  const userName = author?.data?.attributes?.username;
  const postUrl = `post/${data?.id}/${title}`;
  const postType = type?.data?.[0]?.id;
  const userId = author?.data?.id;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {postType === 3 && (
        <>
          <Wrapper className="gap-3 px-3 regular:px-6 mb-6" fullWidth>
            <UserSection id={userId} name={userName} date={publishedAt} avatar={userAvatar} />
            <h2
              itemProp="headline"
              className={clsx(
                'text-2xl font-semibold hover:opacity-90 cursor-pointer break-words',
                isSinglePostPathname && 'hover:!opacity-100 !cursor-default',
              )}
            >
              {title}
            </h2>
            <TagCategorySection
              tags={tags}
              postId={data?.id}
              postTitle={title}
              postUrl={postUrl}
              categories={categories}
              isSinglePost={isSinglePostPathname}
            />
            {isSinglePostPathname && <LinksSection source={source} />}
          </Wrapper>
          <Wrapper className="box-border" fullWidth>
            <Wrapper className="px-3 regular:px-6" fullWidth>
              {extractPlatform(embedMediaUrl as string)?.platform === 'instagram' && (
                <Wrapper
                  className={clsx('md:p-5 md:pb-3 md:embedGradient', content ? 'rounded-t-xl' : 'rounded-xl')}
                  fullWidth
                >
                  <DynamicInstagramEmbed url={embedMediaUrl as string} />
                </Wrapper>
              )}
              {extractPlatform(embedMediaUrl as string)?.platform === 'twitter' && (
                <Wrapper
                  className={clsx('items-center md:p-5 md:embedGradient', content ? 'rounded-t-xl' : 'rounded-xl')}
                  fullWidth
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <DynamicTwitterEmbed url={embedMediaUrl as string} width={530} />
                  </div>
                </Wrapper>
              )}
              {extractPlatform(embedMediaUrl as string)?.platform === 'youtube' && (
                <Wrapper
                  className={clsx('items-center md:p-5 md:embedGradient', content ? 'rounded-t-xl' : 'rounded-xl')}
                  fullWidth
                >
                  <DynamicYouTubeEmbed url={embedMediaUrl as string} width="100%" />
                </Wrapper>
              )}
              {extractPlatform(embedMediaUrl as string)?.platform === 'tiktok' && (
                <Wrapper
                  className={clsx('items-center xs:p-5 xs:embedGradient', content ? 'rounded-t-xl' : 'rounded-xl')}
                  fullWidth
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <DynamicTikTokEmbed url={embedMediaUrl as string} width={325} />
                  </div>
                </Wrapper>
              )}
            </Wrapper>
            {content && (
              <PostContentSection contentIsQuote={contentIsQuote} quoteAuthor={quoteAuthor} content={content} />
            )}
            <p className={clsx('text-gray-300 font-light', additionalText ? 'mt-6 mb-4 px-3 regular:px-6' : 'my-3')}>
              {additionalText}
            </p>
            {!!pollQuestion?.data && <SurveySection pollQuestion={pollQuestion} postId={data?.id} />}
          </Wrapper>
        </>
      )}
    </>
  );
};
