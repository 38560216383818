import { cva } from 'class-variance-authority';

import { closeIcon } from '@/components/icons/CloseIcon';
import { cn } from '@/utils/cn';

export type CloseButtonType = 'default';

interface CloseButtonProps {
  variant: CloseButtonType;
  className?: string;
  onClick: () => void;
}

const closeButtonVariants = cva('inline-flex items-center bg-transparent outline-none rounded-lg text-sm', {
  variants: {
    variant: {
      default: 'text-white hover:text-gray-900 hover:ease-in hover:duration-150 hover:bg-gray-200 p-1.5',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const CloseButton = ({ variant, className, onClick }: CloseButtonProps) => (
  <button type="button" className={cn(closeButtonVariants({ variant, className }))} onClick={onClick}>
    {closeIcon}
  </button>
);
