import Image from 'next/image';

import { Wrapper } from '@/components/atoms/Wrapper';

export const Badge = () => (
  <Wrapper className="items-center px-1 py-0.5 gap-0.5 bg-[#4A1D1D] rounded-md h-fit min-w-max ml-1" row>
    <Image src="/icons/fire.svg" alt="quote" width={14} height={14} />
    <p className="text-xs font-medium text-red-500">HOT</p>
  </Wrapper>
);
