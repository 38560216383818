/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react';
import pl from 'timeago.js/lib/lang/pl';
import { useRouter } from 'next/router';
import * as timeago from 'timeago.js';
import dynamic from 'next/dynamic';
import dayjs from 'dayjs';

import { useUserCookies } from '@/hooks/useUserCookies';
import { AvatarComponent } from '@/components/atoms/AvatarComponent';
import { Wrapper } from '@/components/atoms/Wrapper';

export const DynamicTimeAgo = dynamic(() => import('timeago-react'), { ssr: false });

timeago.register('pl', pl);

interface IUserSectionProps {
  id: number;
  name: string;
  date: string;
  avatar?: string;
}

export const UserSection = ({ id, name, date, avatar }: IUserSectionProps) => {
  const router = useRouter();
  const { id: accountOwnerId, avatar: userAvatar } = useUserCookies();
  const [image, setImage] = useState<string>('');
  const [fullDate, setFullDate] = useState<string>();
  const [timeText, setTimeText] = useState<string>();
  const [isHover, setHover] = useState<boolean>(false);
  const userProfileLink = `/userProfile/${id}`;
  const { asPath } = router;

  useEffect(() => {
    const currentDate = dayjs();
    const addDate = dayjs(date).format('DD.MM.YYYY');
    const hoursDifference = currentDate.diff(date, 'hour');
    const hoveredDate = dayjs(date).format('DD.MM.YYYY HH:mm');

    setFullDate(hoveredDate);

    if (hoursDifference > 96) {
      setTimeText(addDate);
    }
  }, [date]);

  useEffect(() => {
    if (accountOwnerId === id && userAvatar) {
      setImage(userAvatar);
      return;
    }

    if (accountOwnerId !== id && avatar) {
      setImage(avatar);
      return;
    }

    setImage('');
  }, [userAvatar, asPath, accountOwnerId, id, avatar]);

  return (
    <Wrapper
      className="items-center gap-2"
      itemProp="author publisher"
      itemType="https://schema.org/Person"
      itemScope
      row
    >
      <AvatarComponent className="w-4 h-4 min-w-4 min-h-4" avatar={image} link={userProfileLink} />
      <a href={userProfileLink} itemProp="url">
        <p className="text-xs font-semibold" itemProp="name">
          {name}
        </p>
      </a>
      <span className="w-1 h-1 bg-gray-300 rounded-full" />
      <Wrapper
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="text-xs font-light cursor-pointer"
      >
        {isHover ? (
          <p>{fullDate}</p>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>{timeText ? <p>{timeText}</p> : <DynamicTimeAgo datetime={date} locale="pl" live={false} />}</>
        )}
      </Wrapper>
    </Wrapper>
  );
};
