import clsx from 'clsx';
import { useRouter } from 'next/router';

import { putMainPagePost } from '@api/posts/posts';
import { useToast } from '@hooks/useToast';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Button } from '@/components/atoms/Button';
import { HeadlessModal } from '@/components/templates/HeadlessModal';

interface PagePlacementModalProps {
  postId: number;
  isModalVisible: boolean;
  isMainPagePlacement?: boolean;
  closeModal: () => void;
  closePublishPostModal?: () => void;
}

export const PagePlacementModal = ({
  postId,
  isModalVisible,
  isMainPagePlacement = false,
  closeModal,
  closePublishPostModal,
}: PagePlacementModalProps) => {
  const router = useRouter();
  const { showPagePlacementToast } = useToast();

  const handleChangePage = async (isMainPage: boolean) => {
    try {
      await putMainPagePost(postId, isMainPage);
      closeModal();
      if (closePublishPostModal) {
        closePublishPostModal();
      }
      void router.replace(router.asPath);
      if (isMainPage) {
        showPagePlacementToast('Wrzuta przeniesiona na stronę główną', '/icons/home-black.svg');
        return;
      }
      showPagePlacementToast('Wrzuta przeniesiona do szatni', '/icons/emoji-sad-black.svg');
    } catch (error) {
      console.log(error);
    }
  };

  const config = [
    {
      title: 'Przenieś wrzutę na główną',
      paragraph: 'Czy na pewno chcesz przenieść wybraną wrzutę na stronę główną?',
      onClick: () => {
        void handleChangePage(true);
      },
    },
    {
      title: 'Przenieś wrzutę do szatni',
      paragraph: 'Czy na pewno chcesz przenieść wybraną wrzutę do szatni?',
      onClick: () => {
        void handleChangePage(false);
      },
    },
  ];

  const currentConfig = isMainPagePlacement ? config[0] : config[1];
  const { title, paragraph, onClick } = currentConfig;

  return (
    <HeadlessModal
      isOpen={isModalVisible}
      closeModal={closeModal}
      title={title}
      variant="default"
      className="max-w-452"
    >
      <Wrapper className="px-2 pb-2" fullWidth>
        <p className="text-white text-sm mb-6">{paragraph}</p>
        <Wrapper className="gap-4" row fullWidth>
          <Button
            onClick={closeModal}
            variant="outlined"
            className={clsx('hover:text-black hover:bg-white w-1/2', '!text-sm')}
          >
            Anuluj
          </Button>
          <Button
            onClick={onClick}
            variant="contained"
            className="focus:ring-2 focus:ring-primary-300 focus:outline-none hover:bg-primary-700 focus:bg-primary-700 !text-black text-sm font-medium w-1/2"
            type="button"
          >
            Przenieś
          </Button>
        </Wrapper>
      </Wrapper>
    </HeadlessModal>
  );
};
