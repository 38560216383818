import { SVGAttributes } from 'react';
import { cn } from '@/utils/cn';

export const Share = ({ className, ...rest }: SVGAttributes<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cn('stroke-gray-300', className)}
    {...rest}
  >
    <g clipPath="url(#clip0_5718_367)">
      <path
        d="M11.1386 4.99816C11.1386 5.22619 10.9594 5.41345 10.7322 5.43336C4.88733 5.9457 0.250488 10.8661 0.250488 16.8397V19.0149C0.250488 19.4808 0.888958 19.6121 1.07282 19.1841L1.77422 17.5512C3.50813 14.084 6.88372 11.8197 10.6877 11.4646C10.9323 11.4418 11.1386 11.6379 11.1386 11.8835V15.402C11.1386 15.7635 11.5585 15.9626 11.8384 15.7338L20.4005 8.7348C20.6106 8.56313 20.6103 8.24199 20.4 8.0707L11.8378 1.09708C11.5578 0.869042 11.1386 1.06827 11.1386 1.42937V4.99816Z"
        fill="#D4D4D8"
      />
    </g>
    <defs>
      <clipPath id="clip0_5718_367">
        <rect width="20" height="20" fill="white" transform="translate(0.250488)" />
      </clipPath>
    </defs>
  </svg>
);
