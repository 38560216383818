import { useRef, useState, MouseEvent } from 'react';
import { useCopyToClipboard, useOnClickOutside, useSessionStorage } from 'usehooks-ts';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useRouter } from 'next/router';
import { toast } from 'react-hot-toast';
import dynamic from 'next/dynamic';
import ReactGA from 'react-ga4';
import Image from 'next/image';

import { Share } from '@/components/icons/Share';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Toast } from '@/components/atoms/Toast';

import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { PagePlacementModal } from '../PagePlacementModal';
import { ReportViolationModal } from '../ReportViolationModal';
import { DeletePostModal } from './DeletePostModal';

const DynamicDropdown = dynamic(() => import('./Dropdown').then((mod) => mod.Dropdown), { ssr: false });
const DynamicDotsButton = dynamic(() => import('./DotsButton').then((mod) => mod.DotsButton), { ssr: false });

interface ISocialsContainerProps {
  id: number;
  slug: string | null;
  socialTitle: string;
  isMainPage: boolean;
  commentsCount: number;
  isFeedPosts?: boolean;
}

export const SocialsContainer = ({
  id,
  slug,
  socialTitle,
  isMainPage,
  commentsCount,
  isFeedPosts = false,
}: ISocialsContainerProps) => {
  const [userRole] = useSessionStorage('user-role', 1);
  const [, copy] = useCopyToClipboard();
  const router = useRouter();
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const [isMainPagePlacementModal, setMainPagePlacementModal] = useState<boolean>(false);
  const [isLockerPagePlacementModal, setLockerPagePlacementModal] = useState<boolean>(false);
  const [isDeletePostModal, setDeletePostModal] = useState<boolean>(false);
  const [isViolationModal, setViolationModal] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { pathname } = router;
  const singlePostPath = `/post/[...slug]`;
  const isSinglePostPathname = pathname === singlePostPath && !isFeedPosts;
  const postLink = `${process.env.APP_URL}/post/${id}/${slug || ''}`;

  const handleRoute = () => {
    if (isSinglePostPathname) return;
    updateInterstitialActionCount();
    window.location.href = `/post/${id}/${slug || ''}?ref=comments`;
  };

  const getCommentsText = () => {
    if (commentsCount === 0) {
      return 'Skomentuj';
    }
    if (commentsCount === 1) {
      return `${commentsCount} komentarz`;
    }
    if (commentsCount < 5) {
      return `${commentsCount} komentarze`;
    }

    return `${commentsCount} komentarzy`;
  };

  const handleDeletePostModal = () => {
    setDeletePostModal(true);
    setShowDropdown(false);
  };

  const handleMainPagePlacementModal = () => {
    setMainPagePlacementModal(true);
    setShowDropdown(false);
  };

  const handleLockerPagePlacementModal = () => {
    setLockerPagePlacementModal(true);
    setShowDropdown(false);
  };

  const handleReportViolation = () => {
    ReactGA.event('post_share_report_tap');
    setViolationModal(true);
    setShowDropdown(false);
  };

  const onLinkCopy = () => {
    void copy(postLink);
    toast.custom(
      (t) => (
        <Toast isShowToast={t.visible} variant="success">
          <Image src="/icons/link-black.svg" alt="copy-logo" className="text-gray-950" width={20} height={20} />
          <span>|</span>
          <p className="text-sm">Link skopiowany</p>
        </Toast>
      ),
      {
        position: 'top-center',
        duration: 2000,
      },
    );
  };

  useScrollPosition(({ currPos }) => {
    if (showDropdown) {
      setScrollPosition(currPos.y);
      if (scrollPosition && scrollPosition !== currPos.y) {
        setShowDropdown(false);
      }
    }
  });

  useOnClickOutside(containerRef, () => {
    if (isViolationModal || isDeletePostModal || isMainPagePlacementModal || isLockerPagePlacementModal) return;
    setShowDropdown(false);
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e: MouseEvent) => e.stopPropagation()} className="flex">
      <PagePlacementModal
        postId={id}
        isMainPagePlacement
        isModalVisible={isMainPagePlacementModal}
        closeModal={() => setMainPagePlacementModal(false)}
      />
      <PagePlacementModal
        postId={id}
        isModalVisible={isLockerPagePlacementModal}
        closeModal={() => setLockerPagePlacementModal(false)}
      />
      <DeletePostModal postId={id} isModalVisible={isDeletePostModal} closeModal={() => setDeletePostModal(false)} />
      <ReportViolationModal isModalVisible={isViolationModal} closeModal={() => setViolationModal(false)} />
      <Wrapper ref={containerRef} className="relative gap-2 regular:gap-5" row>
        {!isSinglePostPathname && (
          <Wrapper
            className="flex-col regular:flex-row items-center justify-center self-center [&>*]:hover:opacity-70 cursor-pointer gap-0.5 regular:gap-1 regular:mr-3 h-fit"
            onClick={handleRoute}
            row
          >
            <Wrapper className="hover:opacity-70 w-5 h-5 regular:w-6 regular:h-6 relative">
              <Image src="/icons/chat-gray.svg" alt="chat-gray" layout="fill" />
            </Wrapper>
            <p className="text-gray-300 text-xxs regular:text-xs">{getCommentsText()}</p>
          </Wrapper>
        )}
        <Wrapper
          onClick={() => setShowDropdown((state) => !state)}
          className="self-center items-center justify-center regular:hidden relative [&>*]:hover:opacity-70 text-gray-300 cursor-pointer gap-0.5 h-fit"
        >
          <Share />
          <p className="text-xxs">Udostępnij</p>
        </Wrapper>
        <DynamicDropdown
          isMainPage={isMainPage}
          isSinglePostPage={isSinglePostPathname}
          showDropdown={showDropdown}
          postLink={postLink}
          socialTitle={socialTitle}
          userRole={userRole}
          onLinkCopy={onLinkCopy}
          handleDeletePostModal={handleDeletePostModal}
          handleReportViolation={handleReportViolation}
          handlePagePlacementModal={handleMainPagePlacementModal}
          handleLockerPagePlacementModal={handleLockerPagePlacementModal}
        />
        <Wrapper className="regular:flex hidden gap-3 items-center" row>
          <TwitterShareButton url={postLink} title={socialTitle} className="h-6 w-6 relative">
            <Image src="/icons/twitter-gray.svg" alt="twitter-logo" layout="fill" className="hover:opacity-70" />
          </TwitterShareButton>
          <FacebookShareButton url={postLink} className="h-6 w-6 relative">
            <Image src="/icons/facebook-gray.svg" alt="facebook-logo" layout="fill" className="hover:opacity-70" />
          </FacebookShareButton>
          <button onClick={onLinkCopy} className="hover:opacity-70 h-6 w-6 relative">
            <Image src="/icons/link-gray.svg" alt="copy-logo" layout="fill" />
          </button>
          <DynamicDotsButton
            userRole={userRole}
            isSinglePostPage={isSinglePostPathname}
            handleShowDropdown={() => setShowDropdown((state) => !state)}
          />
        </Wrapper>
      </Wrapper>
    </div>
  );
};
