import { SVGAttributes } from 'react';
import { cn } from '@/utils/cn';

export const PostLikeUp = ({ className, ...rest }: SVGAttributes<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    className={cn('stroke-white', className)}
    {...rest}
  >
    <path
      d="M7.7435 18.75C7.3185 18.75 6.9235 18.514 6.7685 18.118C6.42524 17.2443 6.24951 16.3137 6.2505 15.375C6.2505 13.625 6.8495 12.017 7.8525 10.741C8.0035 10.549 8.2255 10.432 8.4525 10.344C8.9255 10.161 9.3425 9.83 9.6645 9.42C10.4408 8.42964 11.4152 7.61221 12.5255 7.02C13.2485 6.636 13.8755 6.064 14.1785 5.305C14.3913 4.77325 14.5006 4.20575 14.5005 3.633V3C14.5005 2.80109 14.5795 2.61032 14.7202 2.46967C14.8608 2.32902 15.0516 2.25 15.2505 2.25C15.8472 2.25 16.4195 2.48705 16.8415 2.90901C17.2634 3.33097 17.5005 3.90326 17.5005 4.5C17.5005 5.652 17.2405 6.743 16.7775 7.718C16.5115 8.276 16.8845 9 17.5025 9H20.6285C21.6545 9 22.5735 9.694 22.6825 10.715C22.7275 11.137 22.7505 11.565 22.7505 12C22.7546 14.7363 21.8195 17.3912 20.1015 19.521C19.7135 20.003 19.1145 20.25 18.4965 20.25H14.4805C13.9975 20.25 13.5165 20.172 13.0575 20.02L9.9435 18.98C9.4846 18.8274 9.00411 18.7497 8.5205 18.75H7.7435ZM2.5815 10.977C2.03042 12.3777 1.74849 13.8698 1.7505 15.375C1.74924 16.5633 1.92448 17.7452 2.2705 18.882C2.5305 19.732 3.3545 20.25 4.2435 20.25H5.1505C5.5955 20.25 5.8705 19.752 5.6735 19.352C5.06364 18.1151 4.74744 16.7541 4.7495 15.375C4.7495 13.667 5.2255 12.07 6.0515 10.709C6.2965 10.306 6.0235 9.75 5.5515 9.75H4.5005C3.6685 9.75 2.8875 10.203 2.5815 10.977Z"
      fill="currentColor"
    />
  </svg>
);
