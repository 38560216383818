import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import clsx from 'clsx';

import { DataItem } from '@/api/posts/interfaces';
import { Wrapper } from '@/components/atoms/Wrapper';

import { ImageContainer } from '../ImageContainer';
import { LinksSection } from '../LinksSection';
import { GalleryBadge } from '../GalleryBadge';
import { UserSection } from '../UserSection';
import { TagCategorySection } from '../TagCategorySection';

export const DynamicGalleryCarousel = dynamic(
  () => import('@components/organisms/GalleryCarousel').then((mod) => mod.GalleryCarousel),
  {
    ssr: false,
  },
);

interface IGalleryPostProps {
  data: DataItem;
  galleryPosts?: { data: DataItem[] } | null;
  isFeedPost?: boolean;
}

export const GalleryPost = ({ data, galleryPosts, isFeedPost = false }: IGalleryPostProps) => {
  const router = useRouter();
  const { pathname } = router;
  const isSinglePostPathname = pathname === '/post/[...slug]' && !isFeedPost;
  const {
    postCoverPicture,
    additionalText,
    relatedPosts,
    mediaSource,
    publishedAt,
    categories,
    content,
    author,
    source,
    title,
    type,
    tags,
  } = data.attributes;
  const userAvatar = author?.data?.attributes?.avatar?.data?.attributes?.formats?.medium?.url;
  const picture = postCoverPicture?.data?.attributes?.formats?.medium?.url;
  const userName = author?.data?.attributes?.username;
  const postsData = [data, ...(relatedPosts?.data || [])];
  const postUrl = `post/${data?.id}/${title}`;
  const postType = type?.data?.[0]?.id;
  const userId = author?.data?.id;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {postType === 4 && (
        <>
          <Wrapper className="gap-3 px-4 mb-6" fullWidth>
            <UserSection id={userId} name={userName} date={publishedAt} avatar={userAvatar} />
            <h2
              className={clsx(
                'text-2xl font-semibold hover:opacity-90 cursor-pointer break-words',
                isSinglePostPathname && 'hover:!opacity-100 !cursor-default',
              )}
              itemProp="headline"
            >
              {title}
            </h2>
            <TagCategorySection
              tags={tags}
              postId={data?.id}
              postTitle={title}
              postUrl={postUrl}
              categories={categories}
              isSinglePost={isSinglePostPathname}
            />
            {isSinglePostPathname && <LinksSection source={source} />}
          </Wrapper>

          {!isSinglePostPathname && (
            <Wrapper className="relative self-center" fullWidth>
              <ImageContainer
                isSinglePostPathname={isSinglePostPathname}
                mediaSource={mediaSource}
                imageSource={picture}
                content={content}
                alt={title}
              />
              <GalleryBadge picturesCount={relatedPosts?.data.length || 1} />
            </Wrapper>
          )}
          {isSinglePostPathname && (
            <DynamicGalleryCarousel relatedPosts={postsData} galleryPosts={galleryPosts || null} />
          )}
          <p className={clsx('text-gray-300 font-light', additionalText ? 'mt-6 mb-4 px-4' : 'my-3')}>
            {additionalText}
          </p>
        </>
      )}
    </>
  );
};
