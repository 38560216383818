import ReactGA from 'react-ga4';

import { Wrapper } from '@/components/atoms/Wrapper';
import { Button } from '@/components/atoms/Button';
import { HeadlessModal } from '@/components/templates/HeadlessModal';

interface ReportViolationModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
}

export const ReportViolationModal = ({ isModalVisible, closeModal }: ReportViolationModalProps) => (
  <HeadlessModal
    isOpen={isModalVisible}
    closeModal={closeModal}
    title="Zgłoś naruszenie"
    variant="default"
    className="max-w-392"
  >
    <Wrapper className="gap-5 px-2 pb-2" fullWidth>
      <p className="text-sm text-white">
        Użytkownicy serwisu akceptując regulamin potwierdzają posiadane przez siebie prawa do publikowania swoich
        treści. Jeśli masz podstawy przypuszczać, że jakaś treść nie powinna jednak się u nas znaleźć wyślij wiadomość
        na{' '}
        <a href="mailto:info@fangol.pl?subject=ZGŁOSZENIE" className="text-primary-600 hover:underline">
          info@fangol.pl
        </a>
        .
      </p>
      <p className="text-sm text-white">
        W tytule wiadomości napisz <span className="font-bold">ZGŁOSZENIE</span>, a w treści koniecznie podaj link do
        danej wrzutki oraz nie zapomnij dopisać argumentację potwierdzającą zasadność Twojego zgłoszenia. Dziękujemy!
      </p>
      <Button
        variant="white"
        onClick={() => {
          closeModal();
          ReactGA.event('post_share_report_proceed');
        }}
      >
        Rozumiem
      </Button>
    </Wrapper>
  </HeadlessModal>
);
