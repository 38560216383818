import { useRouter } from 'next/router';
import clsx from 'clsx';

import { Camera } from '@/components/icons/Camera';
import { Wrapper } from '@/components/atoms/Wrapper';

interface IGalleryBadgeProps {
  picturesCount: number;
}

export const GalleryBadge = ({ picturesCount }: IGalleryBadgeProps) => {
  const router = useRouter();
  const { pathname } = router;
  const singlePostPath = `/post/[...slug]`;
  const isSinglePostPathname = pathname === singlePostPath;

  return (
    <Wrapper
      className={clsx(
        'absolute bottom-1/2 left-1/2 z-10 -translate-x-1/2 translate-y-1/2 p-2 pr-3 xs:p-3 gap-2 bg-white opacity-60 cursor-pointer items-center rounded-xl',
        isSinglePostPathname && 'opacity-100 !cursor-default',
      )}
      row
    >
      <Camera className="w-10 h-10 xs:w-12 xs:h-12" />
      <Wrapper>
        <p className="text-sm font-semibold text-black xs:text-lg">Galeria</p>
        <p className="text-xs text-black xs:text-base -mt-1">{picturesCount} zdjęć</p>
      </Wrapper>
    </Wrapper>
  );
};
