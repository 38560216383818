import { useEffect, useState, MouseEvent } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import { IPollOption, IPollOptionResponse, IPollQuestion } from '@/api/posts/interfaces';
import { postVote } from '@/api/posts/posts';
import { useSurveyStore } from '@/store/useSurveyStore';
import { Wrapper } from '@/components/atoms/Wrapper';
import { cn } from '@/utils/cn';

interface ISurveySectionProps {
  postId: number;
  pollQuestion: IPollQuestion;
}

export const SurveySection = ({ pollQuestion, postId }: ISurveySectionProps) => {
  const initialQuestionsData = pollQuestion?.data?.attributes?.pollOptions?.data;
  const { surveyState, setSurveyState } = useSurveyStore((state) => state);
  const [options, setOptions] = useState<IPollOption[]>(initialQuestionsData);
  const [isContentVisible, setContentVisible] = useState<boolean>(false);
  const [selectedVote, setSelectedVote] = useState<number | null>(null);
  const [isVotingBlocked, setVotingBlocked] = useState<boolean>(false);
  const [isVoted, setVoted] = useState<boolean>(false);
  const question = pollQuestion?.data?.attributes?.question;
  const questionId = pollQuestion?.data?.id;

  const getTotalVotes = (pollOptions: IPollOption[]): number => {
    return pollOptions
      ?.map((option) => option?.attributes?.voteCount)
      ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const initialTotalVotes = getTotalVotes(options);

  const convertResponseToState = (responseArray: IPollOptionResponse[]): IPollOption[] => {
    return responseArray.map(({ id, option, voteCount }) => {
      return {
        id,
        attributes: {
          option,
          voteCount,
        },
      };
    });
  };

  const onVoteClick = async (e: MouseEvent<HTMLElement>, voteId: number) => {
    if (isVotingBlocked) return;
    e.stopPropagation();
    setVotingBlocked(true);

    try {
      const response = await postVote(questionId, voteId);
      const convertedResponse = convertResponseToState(response.pollOptions);
      setOptions(convertedResponse);
      const totalVotes = getTotalVotes(convertedResponse);
      setSurveyState([...surveyState, { postId, answerId: voteId, totalVotes }]);
      setSelectedVote(voteId);
      setVoted(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isVoted) return;

    const surveyVoted = surveyState.find(({ postId: votedPost }) => votedPost === postId);

    if (surveyVoted) {
      setVotingBlocked(true);
      setSelectedVote(surveyVoted?.answerId);
      setVoted(true);
      setContentVisible(true);
      return;
    }
    // add delay to prevent content clipping before zustand is loaded
    const timer = setTimeout(() => {
      setContentVisible(true);
    }, 50);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [surveyState, isContentVisible, isVoted]);

  return (
    <Wrapper className="px-3 regular:px-6 mb-4" fullWidth>
      <p className="font-bold text-white break-words max-w-full">{question}</p>
      <Wrapper className="gap-2 my-3" fullWidth>
        {options.map((option) => {
          const { id } = option;
          const answer = option?.attributes?.option;
          const voteCount = option?.attributes?.voteCount;
          const percentage = (voteCount / initialTotalVotes) * 100 || 0;
          const floatValue = parseFloat(percentage.toString());
          const roundedNumber =
            floatValue - Math.floor(floatValue) >= 0.5 ? Math.ceil(floatValue) : Math.floor(floatValue);

          return (
            <button
              key={id}
              className={cn(
                isContentVisible &&
                  !isVoted &&
                  'border-2 border-transparent rounded-lg relative flex items-center cursor-pointer w-full border-primary-800 hover:border-primary-600 hover:bg-primary-900 hover:bg-opacity-50 hover:ease-in hover:duration-150',
                isContentVisible &&
                  isVoted &&
                  'border-2 border-transparent rounded-lg relative flex items-center cursor-pointer w-full',
                'min-h-40',
              )}
              onClick={(e: MouseEvent<HTMLElement>) => {
                void onVoteClick(e, id);
              }}
            >
              {isContentVisible && (
                <>
                  {isVoted && (
                    <div style={{ width: `${roundedNumber}%` }} className="h-full max-w-full absolute">
                      <div
                        className={cn(
                          'animate-expandWidth will-change-transform bg-gray-600 bg-opacity-25 w-full h-full rounded-lg',
                          roundedNumber !== 0 && 'border-2 border-transparent',
                          selectedVote === id && '!bg-primary-600 !bg-opacity-25',
                        )}
                      />
                    </div>
                  )}
                  <Wrapper
                    className={cn(
                      'justify-between items-center text-gray-300 text-sm px-3 py-1 z-10',
                      selectedVote === id && '!text-primary-600',
                    )}
                    fullWidth
                    row
                  >
                    <p className="font-bold break-words text-start max-w-full">{answer}</p>
                    {isVoted && (
                      <p className="flex items-center justify-end gap-2 ml-1 min-w-68">
                        {selectedVote === id && (
                          <CheckCircleIcon width={24} height={24} strokeWidth={2} className="text-primary-600" />
                        )}
                        {roundedNumber}%
                      </p>
                    )}
                  </Wrapper>
                </>
              )}
            </button>
          );
        })}
      </Wrapper>
      <Wrapper className="text-xs text-gray-300 justify-between" fullWidth row>
        {isVoted && isContentVisible && <p className="text-xs">Oddane głosy: {initialTotalVotes}</p>}
        {!isVoted && isContentVisible && <p className="font-bold ">Zagłosuj by zobaczyć wyniki!</p>}
      </Wrapper>
    </Wrapper>
  );
};
