import clsx from 'clsx';

import { Wrapper } from '../Wrapper';

interface ICalendarContainerProps {
  counter: number;
  isDislikedPost?: boolean;
  isLikedPost?: boolean;
}

export const CalendarContainer = ({ counter, isDislikedPost, isLikedPost }: ICalendarContainerProps) => {
  const activeVerticalLine = (isLikedPost && '!bg-primary-600') || (isDislikedPost && '!bg-red-600');
  const activeCounter = (isLikedPost && '!text-primary-600') || (isDislikedPost && '!text-red-600');
  const activeContainer =
    (isLikedPost && '!border-primary-600 !bg-primary-800 !bg-opacity-50') ||
    (isDislikedPost && '!border-red-600 !bg-red-900 !bg-opacity-50');

  return (
    <Wrapper
      className={clsx(
        'border border-zinc-600 rounded-lg items-center justify-center bg-zinc-900 min-w-48 h-12 relative p-2',
        activeContainer,
      )}
    >
      <Wrapper
        className={clsx(
          'absolute top-0 -translate-y-1/2 bg-zinc-600 rounded-md w-0.5 h-2',
          activeVerticalLine,
          'left-1/2 -translate-x-3',
        )}
      />
      <Wrapper
        className={clsx(
          'absolute top-0 -translate-y-1/2 bg-zinc-600 rounded-md w-0.5 h-2',
          activeVerticalLine,
          'left-1/2 -translate-x-1/2',
        )}
      />
      <Wrapper
        className={clsx(
          'absolute top-0 -translate-y-1/2 bg-zinc-600 rounded-md w-0.5 h-2',
          activeVerticalLine,
          'right-1/2 translate-x-3',
        )}
      />
      <p className={clsx(activeCounter, 'text-2xl font-semibold')}>{counter}</p>
    </Wrapper>
  );
};
