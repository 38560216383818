import { useRouter } from 'next/router';
import clsx from 'clsx';

import { deletePost } from '@/api/posts/posts';
import { useToast } from '@/hooks/useToast';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Button } from '@/components/atoms/Button';
import { HeadlessModal } from '@/components/templates/HeadlessModal';

interface DeletePostModalProps {
  postId: number;
  isModalVisible: boolean;
  closeModal: () => void;
}

export const DeletePostModal = ({ postId, isModalVisible, closeModal }: DeletePostModalProps) => {
  const router = useRouter();
  const { deletedPostToast } = useToast();

  const refreshData = () => {
    void router.replace(router.asPath);
  };

  const handleDeletePost = async () => {
    try {
      await deletePost(postId);
      closeModal();
      refreshData();
      deletedPostToast();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <HeadlessModal
      isOpen={isModalVisible}
      closeModal={closeModal}
      title="Usuń wrzutę"
      variant="default"
      className="max-w-452"
    >
      <Wrapper className="px-2 pb-2" fullWidth>
        <p className="text-white text-sm mb-6">Czy na pewno chcesz usunąć wybraną wrzutę?</p>
        <Wrapper className="gap-4" row fullWidth>
          <Button
            onClick={closeModal}
            variant="outlined"
            className={clsx('hover:text-black hover:bg-white w-1/2', '!text-sm')}
          >
            Anuluj
          </Button>
          <Button
            onClick={() => {
              void handleDeletePost();
            }}
            variant="accountDelete"
            className="w-1/2"
            type="button"
          >
            Usuń
          </Button>
        </Wrapper>
      </Wrapper>
    </HeadlessModal>
  );
};
