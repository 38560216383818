import { MouseEvent, useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

import { Wrapper } from '@/components/atoms/Wrapper';

interface ImageContainerProps {
  alt: string;
  content: string;
  imageSource: string;
  mediaSource: string;
  isSinglePostPathname: boolean;
}

export const ImageContainer = ({
  alt,
  content,
  imageSource,
  mediaSource,
  isSinglePostPathname,
}: ImageContainerProps) => {
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const [showFullImage, setShowFullImage] = useState<boolean>(true);
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isSinglePostPathname) return;
    const setImageHeightAndShowButton = (height: number) => {
      setImageHeight(height);
      setShowFullImage(false);
      setIsShowButton(true);
    };

    const image = imageRef.current;

    if (image) {
      if (image.height > 1500) {
        setImageHeightAndShowButton(image.width);
      }
    }
  }, [imageRef, isImageLoaded, isSinglePostPathname]);

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowFullImage(true);
    setIsShowButton(false);
  };

  return (
    <div
      style={{ height: showFullImage ? '100%' : `${imageHeight}px` }}
      className={clsx('overflow-hidden relative mx-3 regular:mx-6', content ? 'rounded-t-xl' : 'rounded-xl')}
    >
      <img
        alt={alt}
        className={clsx('w-full', content ? 'rounded-t-xl' : 'rounded-xl', !isSinglePostPathname && 'cursor-pointer')}
        ref={imageRef}
        src={imageSource}
        onLoad={() => setImageLoaded(true)}
        itemProp="image"
      />
      {!!mediaSource && isSinglePostPathname && (
        <a href={mediaSource} className="absolute bottom-0 right-0" target="_blank" rel="noreferrer">
          <Wrapper className="bg-gray-800 bg-opacity-5 backdrop-blur-lg py-2 px-3 gap-1 rounded-tl-lg max-w-200" row>
            <p className="text-xs">Zdjęcie:</p>
            <p className="text-xs truncate">{mediaSource}</p>
          </Wrapper>
        </a>
      )}
      {isShowButton && (
        <>
          <div className="bg-gradient-to-b from-transparent to-gray-950 absolute bottom-0 left-0 right-0 h-2/3" />
          <div
            className={clsx(
              'flex items-end justify-center absolute inset-0 mb-2',
              !isSinglePostPathname && 'cursor-pointer',
            )}
          >
            <button
              className="bg-gray-800 hover:bg-gray-900 bg-opacity-50 border border-gray-600 rounded-lg text-sm font-medium text-white flex items-center gap-3 px-4 py-2.5"
              onClick={handleButtonClick}
            >
              Rozwiń zdjęcie
              <ChevronDownIcon width={13} strokeWidth={3} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
