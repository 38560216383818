import { SVGAttributes } from 'react';
import { cn } from '@/utils/cn';

export const PostLikeDown = ({ className, ...rest }: SVGAttributes<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={cn('stroke-white', className)} {...rest}>
    <path
      d="M15.98 5.25H17.015C17.8229 6.47385 18.2524 7.90854 18.25 9.375C18.2524 10.8415 17.8229 12.2762 17.015 13.5H16.867C16.061 13.5 15.333 13.946 14.836 14.58C14.0598 15.5704 13.0853 16.3878 11.975 16.98C11.252 17.364 10.625 17.936 10.322 18.695C10.1092 19.2268 9.9999 19.7942 10 20.367V21C10 21.1989 9.92098 21.3897 9.78033 21.5303C9.63968 21.671 9.44891 21.75 9.25 21.75C8.65326 21.75 8.08097 21.5129 7.65901 21.091C7.23705 20.669 7 20.0967 7 19.5C7 18.348 7.26 17.257 7.723 16.282C7.99 15.724 7.616 15 6.998 15H3.872C2.846 15 1.927 14.306 1.818 13.285C1.77262 12.8582 1.74992 12.4292 1.75 12C1.75 9.152 2.742 6.536 4.399 4.479C4.787 3.997 5.386 3.75 6.004 3.75H10.02C10.5036 3.74971 10.9841 3.82737 11.443 3.98L14.557 5.02C15.0159 5.17263 15.4964 5.2503 15.98 5.25ZM21.919 13.773C22.455 12.411 22.75 10.928 22.75 9.375C22.75 8.155 22.568 6.977 22.23 5.868C21.97 5.018 21.146 4.5 20.257 4.5H19.35C18.905 4.5 18.63 4.998 18.827 5.398C19.418 6.598 19.751 7.948 19.751 9.375C19.7537 11.0204 19.3032 12.6347 18.449 14.041C18.204 14.444 18.477 15 18.949 15H20.002C20.834 15 21.613 14.547 21.919 13.773Z"
      fill="currentColor"
    />
  </svg>
);
